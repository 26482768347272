<h1 mat-dialog-title>
    <mat-icon>verified_user</mat-icon> {{user.salutation | salutation}} {{user.firstname}} {{user.lastname}}
</h1>
<div mat-dialog-content>
    <table class="user-table">
        <tbody>
            <tr>
                <td>Benutzername</td>
                <td>{{user.username}}</td>
            </tr>
            <tr>
                <td>Benutzerrolle</td>
                <td>{{user.role}}</td>
            </tr>
            <tr>
                <td>Benutzerstatus</td>
                <td>{{user.userStatus}}</td>
            </tr>
        </tbody>
    </table>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Schlie&szlig;en</button>
</div>
