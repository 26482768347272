// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

let apiUrl, crudUrl;

if (
    location.hostname === 'localhost' || 
    location.hostname === '127.0.0.1' || 
    location.hostname === 'test.workonweb.de' 
) {
    apiUrl = 'http://test.workonweb.de:9080'
    crudUrl = 'http://test.workonweb.de/backend'
} else {
    apiUrl = 'https://orange-stage.workonweb.de/api'
    crudUrl = 'https://pdf-stage.workonweb.de/backend'
}

export const environment = {
    production: false,
    crudUrl: crudUrl,
    apiUrl: apiUrl,
    clientId: 'orange-dashboard-nsi3l8jp',
    filePaths: {
        pdfStation: crudUrl + '/tmp/bahnhof/',
        pdfEvaNumber: crudUrl + '/tmp/eva/',
        previewImage: crudUrl + '/tmp/gif/',
        csvFile: crudUrl + '/tmp/'
    }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
