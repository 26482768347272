import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@core/services/auth.service';
import { SharedService } from '@core/services/shared.service';

import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'logout-button',
  templateUrl: './logout-button.component.html',
  styleUrls: ['./logout-button.component.scss']
})
export class LogoutButtonComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private sharedService: SharedService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  logout(event: any) {

    this.snackBar.open('Abmelden...', '', { duration: 2000, panelClass: 'info-snack' });

    this.auth.revokeAccessToken().subscribe(
        (data: any) => {
            
            this.snackBar.open('Abmelden erfolgreich. Bis bald!', '', { duration: 2000, panelClass: 'success-snack' });
            this.auth.setLoggedIn(false)
            this.sharedService.removeCurrentUser()
            this.router.navigate(['login'])

        },
        (err: any) => { }
    )

}


}
