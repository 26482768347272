<!-- Menue -->
<div class="flex flex-row gap-7 justify-start items-center p-5">
    <button mat-flat-button color="primary" matBadge="{{selection.selected.length}}" matBadgePosition="after" matBadgeColor="accent" [ngClass]="{'spinner': pdfLoading}" [disabled]="pdfLoading" (click)="makePdfFromSelection(selection.selected)">PDF erstellen</button>
    <button mat-flat-button color="warn" matBadge="{{selection.selected.length}}" matBadgePosition="after" matBadgeColor="accent" [ngClass]="{'spinner': pdfLoading}" [disabled]="pdfLoading" (click)="removePdfFromSelection(selection.selected)">PDF löschen</button>
    <button mat-flat-button color="primary" matBadge="{{fileCounts.EVA}}" matBadgePosition="after" matBadgeColor="accent" [class.spinner]="csvLoading" [disabled]="csvLoading" (click)="makeCsv()">CSV erstellen</button>
    <a mat-flat-button color="primary" href="{{pathToCSV}}" target="_blank">CSV herunterladen</a>
    <button mat-flat-button color="primary" matBadge="{{fileCounts.PDF}}" matBadgePosition="after" matBadgeColor="accent" [class.spinner]="zipLoading" [disabled]="zipLoading" (click)="makeZip()">Archiv erstellen</button>
    <button mat-flat-button color="primary" (click)="openBottomSheet()" [disabled]="zipLoading">Archive herunterladen</button>
    <mat-form-field class="filter w-[350px]" appearance="standard">
        <mat-label><mat-icon>filter_list</mat-icon> Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="" [(ngModel)]="filterInput" #filter>
        <button *ngIf="filterInput" matSuffix mat-icon-button aria-label="Clear" (click)="filterInput='';dataSource.filter=''">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
</div>

<!-- Main -->
<div [hidden]="isLoading">

    <cdk-virtual-scroll-viewport itemSize="500" class="w-full h-[calc(100vh-64px-110px-56px)]">
        
        <table mat-table [dataSource]="dataSource" matSort class="station-table mat-elevation-z1" scrollStrategy="virtual">

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> 
                    Bahnh&ouml;fe ({{stationsCount}})
                </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- EVA-number Column -->
            <ng-container matColumnDef="evaNumbers">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> EVA-Nr. </th>
                <td mat-cell *matCellDef="let element"> {{element.evaNumbers.join(', ')}} </td>
            </ng-container>

            <!-- State Column -->
            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Parkraum Status </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="!element.spaces.hasDrafts && element.spaces.onlyPublished.length > 0" class="well published" 
                        matTooltip="Alle Parkräume dieses Bahnhofs sind publiziert." 
                        aria-label="This station has fully published spaces">
                        Publiziert
                    </div>
                    <div *ngIf="element.spaces.hasDrafts" class="well draft" 
                        matTooltip="Parkräume auf Entwurf: {{element.spaces.drafts.join(', ')}}" 
                        aria-label="This station has one or more draft spaces">
                        Entwurf
                    </div>
                    <div *ngIf="element.spaces.onlyPublished.length === 0" class="well offline" 
                        matTooltip="Alle zugehörigen Parkräume dieses Bahnhofs sind offline." 
                        aria-label="No spaces found.">
                        Offline
                    </div>
                </td>
            </ng-container>

            <!-- Image Preview Column -->
            <ng-container matColumnDef="image_preview">
                <th mat-header-cell *matHeaderCellDef> Vorschaubild </th>
                <td mat-cell *matCellDef="let element">
                    
                    <mat-form-field class="preview-image-select" appearance="standard">
                        <mat-label>Zoom</mat-label>
                        <mat-select [(ngModel)]="element.pdfmeta.zoom2" (selectionChange)="onZoomChange(zoom2)">
                            <mat-option [value]="level" *ngFor="let level of zoomLevelsPreviewImage">{{level}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button mat-icon-button aria-label="Save zoom" (click)="saveMapZoom(zoom2, element.pdfmeta.zoom2, element.id, element.name)" matTooltip="Zoomstufe für {{element.name}} speichern">
                        <mat-icon aria-hidden="false" aria-label="save icon" id="zoom2" #zoom2>save</mat-icon>
                    </button>

                    <button mat-icon-button (click)="getMapUrl(element.id, element.geoLocation.latitude, element.geoLocation.longitude, element.pdfmeta.zoom2, element.spaces.draftsIncluded, [], 'small', false)" aria-label="Thumbnail image preview" matTooltip="Vorschau anzeigen">
                        <mat-icon aria-hidden="false" aria-label="preview icon">preview</mat-icon>
                    </button>

                    <a mat-icon-button (click)="openFile(element.evaNumbers[0], 'previewImage', true)" aria-label="View generated thumbnail" matTooltip="Bild anzeigen">
                        <mat-icon aria-hidden="false" aria-label="image icon">image</mat-icon>
                    </a>

                </td>
            </ng-container>

            <!-- Map Image Column -->
            <ng-container matColumnDef="map_image">
                <th mat-header-cell *matHeaderCellDef> Kartenbild </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field class="map-image-select" appearance="standard">
                        <mat-label>Zoom</mat-label>
                        <mat-select [(ngModel)]="element.pdfmeta.zoom" (selectionChange)="onZoomChange(zoom)">
                            <mat-option *ngFor="let level of zoomLevelsMapImage" [value]="level">{{level}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-icon-button aria-label="Save zoom" (click)="saveMapZoom(zoom, element.pdfmeta.zoom, element.id, element.name)">
                        <mat-icon aria-hidden="false" aria-label="save icon" matTooltip="Zoomstufe für {{element.name}} speichern" id="zoom" #zoom>save_outline</mat-icon>
                    </button>
                    <button mat-icon-button (click)="getMapUrl(element.id, element.geoLocation.latitude, element.geoLocation.longitude, element.pdfmeta.zoom, element.spaces.draftsIncluded, [], 'big', false)" aria-label="Static map image preview" matTooltip="Vorschau Kartenbild">
                        <mat-icon aria-hidden="false" aria-label="preview icon">preview</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- PDF Preview Column -->
            <ng-container matColumnDef="pdf_preview">
                <th mat-header-cell *matHeaderCellDef> PDF Vorschau </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button aria-label="PDF Preview" (click)="openPdfPreviewDialog(element)" matTooltip="PDF Vorschau">
                        <mat-icon aria-hidden="false" aria-label="preview icon">preview</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- PDF Station Column -->
            <ng-container matColumnDef="station_pdf">
                <th mat-header-cell *matHeaderCellDef> Bahnhof PDF </th>
                <td mat-cell *matCellDef="let element">
                    <a mat-icon-button [disabled]="!element.pdfmeta.filed" (click)="openFile(element.name, 'pdfStation', true)" aria-label="Bahnhof PDF ansehen" matTooltip="{{element.name | sanitizeFilename}}.pdf">
                        <mat-icon aria-hidden="false" aria-label="save icon">picture_as_pdf</mat-icon>
                    </a>
                </td>
            </ng-container>

            <!-- PDF EVA Column -->
            <ng-container matColumnDef="eva_pdf">
                <th mat-header-cell *matHeaderCellDef> EVA-Nr. PDF </th>
                <td mat-cell *matCellDef="let element">
                    <a *ngFor="let evaNumber of element.evaNumbers" mat-icon-button [disabled]="!element.pdfmeta.filed" (click)="openFile(evaNumber, 'pdfEvaNumber', true)" aria-label="EVA-Nr. PDF ansehen" matTooltip="{{evaNumber}}.pdf">
                        <mat-icon aria-hidden="false" aria-label="PDF">picture_as_pdf</mat-icon>
                    </a>
                </td>
            </ng-container>

            <!-- Timestamp Column -->
            <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Erstellungsdatum </th>
                <td mat-cell *matCellDef="let element"> {{element.pdfmeta.created | date:'EE, dd. MMM yyyy'}} <br>
                    <small>{{element.pdfmeta.created | date:'HH:mm:ss \'Uhr\''}}</small></td>
            </ng-container>

            <!-- User Column -->
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Benutzer </th>
                <td mat-cell *matCellDef="let element"> 
                    <mat-icon *ngIf="element.pdfmeta.user" aria-hidden="false" aria-label="save icon">person</mat-icon> {{element.pdfmeta.user}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11">Keine Daten für "{{filter.value}}"</td>
            </tr>
        </table>

    </cdk-virtual-scroll-viewport>


    <mat-paginator [length]="stationsCount" [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>

</div>

<!-- Loading Spinner -->
<div *ngIf="isLoading" class="absolute flex flex-row justify-around items-center w-full h-[calc(100vh-64px)] z-50 top-[64px] bg-[#f1f1f1]">
    <mat-spinner></mat-spinner>
</div>