import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../layout/header/user-dialog/user.interface';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DataService {


    constructor(
        private http: HttpClient
    ) { }

    getUser(): Observable<User> {
        return this.http.get<User>(`${environment.apiUrl}/v2/users/me`)
    }

    getStations(params: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/stations`, {params: params})
    }

    getSpaces(params: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/spaces`, {params: params})
    }

    getMobies(params: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/mobies`, {params: params})
    }

}
