import { Component, OnInit } from '@angular/core';
import { SharedService } from '@core/services/shared.service'; 
import { User } from './user.interface';

@Component({
    selector: 'user-dialog',
    templateUrl: './user-dialog.component.html',
    styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {

    user: any

    constructor(
        private sharedService: SharedService
    ) { }

    ngOnInit(): void {
        this.sharedService.currentUser.subscribe(data => this.user = data)
    }

}
