import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { Mobi } from './mobi.interface';

@Injectable({
    providedIn: 'root'
})
export class MapService {

    constructor(
        private http: HttpClient
    ) { }

    saveZoomLevel(zoom: any): Observable<any> {
        return this.http.post(`${environment.crudUrl}/saveZoomLevel.php`, zoom)
    }

    buildStaticUrl(stationId:string, stationLat:string, stationLng:string, zoomLevel:string, spacesArray:any, mobiesArray: any, imageSize:string, linkOnly:boolean):string {
        
        // In mapbox we have two different maps, one with DB logo at every station (for preview gif) and one map without DB logo (for PDF)
        const mapboxID: string = imageSize === 'big' ? 'cjado39w85e1l2smu1ko4bopf' : 'cje78whgqh2ls2rmx24vlfbqs'
        // In PDF maps (big) we add the DB logo ourself, in the GIF preview image the DB logo comes by mapbox map ID
        const logoType: string = imageSize === 'big' ? 'db-logo.png' : 'transparent.png'
        const iconSize: string = imageSize === 'big' ? '229x75' : '58x19'
        const size: string = imageSize === 'big' ? '1280x850@2x' : '363x205'

        const baseUrl: string = `https://api.mapbox.com/styles/v1/workonweb/${mapboxID}/static/`;
        const token: string = '?access_token=pk.eyJ1Ijoid29ya29ud2ViIiwiYSI6ImNqNzZid3lzbzE1cXAzM2wzNDdlZmo4OWYifQ.GbH5YKLNTuzOllVwDqvuOg';

        const iconRed: string = 'url-' + encodeURIComponent(`https://media.workonweb.de/bahnpark/files/static/images/png/${iconSize}/`);
        const iconDB: string = 'url-' + encodeURIComponent(`https://media.workonweb.de/bahnpark/files/static/images/png/${logoType}`);
        const iconMobi: string = 'url-' + encodeURIComponent('https://media.workonweb.de/bahnpark/files/static/images/png/mobi-icons/');

        const logo: string = '&logo=true';
        const attribution: string = '&attribution=true';
        // const before_layer:string = '&before_layer=road-label-small';
        // const language:string = '&language=de';

        // pseudo marker for zoom parameter
        let marker:string = (zoomLevel === 'auto' || typeof zoomLevel === 'undefined') ? '/auto/' : '/' + stationLng + ',' + stationLat + ',' + zoomLevel + '/';
        // station marker/custom icon also center of map
        let icons:string = iconDB + '(' + stationLng + ',' + stationLat + '),';
        // Mobi icons
        if ( mobiesArray ) {
            mobiesArray.forEach((mobi: Mobi) => {
                icons += `${iconMobi}${mobi.mobiType}.png(${mobi.geoLocation.longitude},${mobi.geoLocation.latitude}),`;
            })
        }
        // spaces markers/custom icons
        for ( let space of spacesArray ) {
            if ( parseInt(space.station.id) === parseInt(stationId) ) {
                if ( space.geoLocation && space.label ) {
                    icons += iconRed + space.label + '.png' + '(' + space.geoLocation.longitude + ',' + space.geoLocation.latitude + '),';
                }
            }
        }
        // remove last comma
        icons = icons.slice(0, -1);
        // combine url parameters
        let mapboxURL = baseUrl + icons + marker + size + token + logo + attribution; // . before_layer; 
        // open link in new window
        if (!linkOnly) {
            window.open(mapboxURL, '_blank');
            return `Kartenbild in neuem Tab verfügbar.`;
        }

        return mapboxURL;

    }

}
