import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phantomQuerystring'
})
export class PhantomQuerystringPipe implements PipeTransform {

  transform(link: string): string {

    const a: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const tokenLength: number = 50;
    
    let token: string = '';

    for (let i = 0; i < tokenLength; i++) token += a.charAt(Math.floor(Math.random() * a.length));

    return link + '?' + token;

  }

}
