import { Component, OnInit, ViewChild, AfterViewInit, Renderer2, ElementRef, ViewEncapsulation } from '@angular/core';
import { forkJoin } from 'rxjs';

import { MapService } from './map.service';
import { PdfService } from './pdf.service';
import { DataService } from '../core/services/data.service';
import { Station } from './station.interface';
import { SanitizeFilenamePipe } from '../shared/pipes/sanitize-filename.pipe';
import { PhantomQuerystringPipe } from '../shared/pipes/phantom-querystring.pipe';

import { environment } from '@environments/environment';

import { SnotifyService, SnotifyPosition } from 'ng-snotify';
import { SharedService } from '@core/services/shared.service';

import { MatSort, MatSortable } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { PreviewDialogComponent } from './preview-dialog/preview-dialog.component'
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ArchivesBottomsheetComponent } from './archives-bottomsheet/archives-bottomsheet.component';

import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Space } from './space.interface';
import { Store } from '@ngxs/store';
import { PaginatorChanged, PaginatorStateModel } from '@core/states/paginator/paginator.action';
import { PaginatorState } from '@core/states/paginator/paginator.state';
import { Mobi } from './mobi.interface';

/**
 * @title Home Component
 * 
 */
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

    isLoading: boolean;

    zipLoading: boolean;
    csvLoading: boolean;
    pdfLoading: boolean;

    stationsCount: number = 0;
    fileCounts: any = { eva: 0, zip: 0, gif: 0, pdf: 0 };

    zoomLevelsMapImage: string[] = this.zoomRange(15, 18, 0.1);
    zoomLevelsPreviewImage: string[] = this.zoomRange(13, 17, 0.1);

    displayedColumns: string[] = [
        'select',
        'name',
        'evaNumbers',
        'state',
        'image_preview',
        'map_image',
        'pdf_preview',
        'station_pdf',
        'eva_pdf',
        'timestamp',
        'user'
    ]
    dataSource = new MatTableDataSource<Station>();
    selection = new SelectionModel<Station>(true, []);
    sortedData: Station[];

    // pageSize: number = 200; set default in paginator state
    pageSizeOptions: number[] = [5, 10, 20, 100, 200];
    pageEvent: PageEvent;

    username: string;

    filterInput: string = '';

    pathToCSV: string = `${environment.filePaths.csvFile}fahrplanauskunft.csv`

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private sharedService: SharedService,
        private dataService: DataService,
        private pdfService: PdfService,
        private mapService: MapService,
        private sanitizeFilenamePipe: SanitizeFilenamePipe,
        private phantomQuerystringPipe: PhantomQuerystringPipe,
        private snotifyService: SnotifyService,
        private bottomSheet: MatBottomSheet,
        public dialog: MatDialog,
        private renderer: Renderer2,
        private store: Store
    ) { }

    ngOnInit(): void {

        this.init();
    }


    init(): void {

        this.isLoading = true;

        forkJoin([

            this.dataService.getStations({
                size: 2000,
                channel: 'PDF',
                visibility: 'ONLINE'
            }),
            this.dataService.getMobies({
                size: 2000,
                channel: 'PDF',
                publishingStatus: 'PUBLISHED',
                visibility: 'ONLINE'
            }),
            this.dataService.getMobies({
                size: 2000,
                channel: 'PDF',
                visibility: 'ONLINE'
            }),
            this.dataService.getSpaces({
                size: 2000,
                channel: 'PDF',
                visibility: 'ONLINE',
                publishingStatus: 'PUBLISHED',
                source: 'DBBAHNPARK'
            }),
            this.dataService.getSpaces({
                size: 2000,
                channel: 'PDF',
                visibility: 'ONLINE',
                source: 'DBBAHNPARK'
            }),
            this.pdfService.getMetaData()

        ]).subscribe((results: any) => {

            let stations: Station[] = results[0].content;
            let mobiesPublished: Mobi[] = results[1].content;
            let mobiesWithDrafts: Mobi[] = results[2].content;
            let spacesPublished: Space[] = results[3].content;
            let spacesWithDrafts: Space[] = results[4].content;
            let metas: any = results[5].metadata;

            // stations = stations.map((obj: any) => ({ ...obj, spaces: [] }))

            for (let station of stations) {
                // init property spaces array
                station.spaces = {
                    onlyPublished: [],
                    draftsIncluded: [],
                    drafts: [],
                    hasDrafts: false
                }

                // append mobies to station object
                station.mobies = {
                    onlyPublished: mobiesPublished.filter((item: Mobi) => item.station.id === station.id),
                    draftsIncluded: mobiesWithDrafts.filter((item: Mobi) => item.station.id === station.id)
                }

                for ( let space of spacesPublished ) {
                    if (space.station.id === station.id) {
                        // append spaces to station object
                        station.spaces.onlyPublished.push(space);
                    }
                }

                for ( let draft of spacesWithDrafts ) {
                    if (draft.station.id === station.id) {
                        // append drafts to station object
                        station.spaces.draftsIncluded.push(draft);
                        // for the "Entwurf" button
                        if ( draft.publishingStatus === 'DRAFT' ) station.spaces.drafts.push(draft.title)
                    }
                }
                // Check array if there are really drafts
                station.spaces.hasDrafts = station.spaces.draftsIncluded.some((item: any) => item.publishingStatus === 'DRAFT')
                // station.spaces.drafts.length > 0 ? true : false

                // init property pdfmeta array
                station.pdfmeta = []
                // add pdf meta data to the station object
                for (let meta of metas) {
                    if ( station.id === parseInt(meta.id) )  {
                        station.pdfmeta = meta
                    }
                }
            }

            this.stationsCount = stations.length;

            this.dataSource.paginator = this.paginator;

            /** init table sort alread sorted by name ASC */
            // this.sort.sort(({ id: 'name', start: 'asc' }) as MatSortable);
            this.dataSource.sortingDataAccessor = (item: any, property: any) => {
                switch (property) {
                    case 'name': return item.name;
                    case 'evaNumbers': return item.evaNumbers;
                    case 'state': return item.spaces.hasDrafts;
                    case 'timestamp': return item.pdfmeta.created;
                    case 'user': return item.pdfmeta.user;
                    default: return item[property];
                }
            };

            this.dataSource.sort = this.sort;

            this.dataSource.data = stations;
            // Has to be due to lifecycle timing issues, maybe there is a better workaround
            setTimeout(() => {
                this.paginator.pageIndex = this.store.selectSnapshot(PaginatorState.getCurrentPageIndex);
                this.paginator.pageSize = this.store.selectSnapshot(PaginatorState.getCurrentPageSize);
                this.paginator.page.emit({
                    length: this.stationsCount,
                    pageIndex: this.paginator.pageIndex,
                    pageSize: this.paginator.pageSize,
                });

                this.isLoading = false;
            });
            // console.log(this.dataSource.data)
        });

        /** Get user */
        this.dataService.getUser().subscribe(
            (data: any) => {
                this.username = `${data.firstname} ${data.lastname}`;
                // console.log(data, this.username)
                this.sharedService.setCurrentUser(data)
            }
        )

        /** Count all files in folders */
        this.pdfService.countFiles().subscribe(
            (data: any) => {
                this.fileCounts = data;
            }
        )

    }

    /** Dispatch paginator event */
	pageChanged(event: PaginatorStateModel) {
        // console.log(event)
		this.store.dispatch(new PaginatorChanged(event))
	}

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.dataSource.data);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Station): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }

    /** Returns array of zoom levels as strings due to mat-select */
    zoomRange(start: number, stop: number, step: number): string[] {
        const range = Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
        return ['auto', ...range].map(o => String(o));
    }

    /** Keyup filter event */
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    /** Updates meta data from backend */
    updatePdfMetaData() {
        this.pdfService.getMetaData().subscribe(
            (data: any) => {
                for (let dataSource of this.dataSource.data) {
                    for (let meta of data.metadata) {
                        if (dataSource.id === parseInt(meta.id)) dataSource.pdfmeta = meta;
                    }
                }
            }
        )
        this.pdfService.countFiles().subscribe(
            (data: any) => {
                this.fileCounts = data;
            }
        )
    }

    /** Creates PDF from selected rows */
    makePdfFromSelection(selected: any) {

        if ( selected.length > 0 ) {

            this.pdfLoading = true;
            // use deep cloned object from now on, so filtering won't affect the preview PDF
            // const items = JSON.parse(JSON.stringify(selected));

            let max = selected.length;
            let count = 0;

            for ( let item of selected ) {
                // filter published spaces, no drafts allowed
                // item.spaces = item.spaces.filter((space: any) => space.publishingStatus == 'PUBLISHED')
                // if there are published spaces left, continue generating PDF
                if ( item.spaces.onlyPublished.length > 0 ) {

                    item.mapUrl = this.getMapUrl(
                        item.id, 
                        item.geoLocation.latitude, 
                        item.geoLocation.longitude,
                        item.pdfmeta.zoom, 
                        item.spaces.onlyPublished,
                        item.mobies.onlyPublished, 
                        'big', 
                        true
                    );

                    item.thumbnailUrl = this.getMapUrl(
                        item.id, 
                        item.geoLocation.latitude, 
                        item.geoLocation.longitude, 
                        item.pdfmeta.zoom2, 
                        item.spaces.onlyPublished, 
                        null,
                        'thumbnail', 
                        true
                    );

                    item.filename = this.sanitizeFilenamePipe.transform(item.name);
                    item.user = this.username;

                    // create pdf
                    this.pdfService.createPdf(item).subscribe(
                        (data: any) => {
                            this.snotifyService.success(data.userMessage, { position: SnotifyPosition.rightTop });
                            count++;
                            // console.log(item)
                            // PDF job done
                            if (count === max) {
                                this.updatePdfMetaData();
                                this.pdfLoading = false;
                            }
                        },
                        (err: any) => {
                            // console.log(err)
                            this.snotifyService.error(
                                err, 
                                { position: SnotifyPosition.rightTop, timeout: 10000 }
                            );
                            this.pdfLoading = false;
                        }
                    )

                } else {
                    count++;
                    this.snotifyService.error(
                        `Keine publizierten Parkräume für den Bahnhof ${item.name} gefunden.`, 
                        { position: SnotifyPosition.rightTop, timeout: 10000 }
                    );
                    // PDF job done
                    if (count === max) {
                        this.updatePdfMetaData();
                        this.pdfLoading = false;
                    }
                }
            }
        }
    }

    /** Deletes PDF from selected rows */
    removePdfFromSelection(selected: any) {

        if ( selected.length > 0 ) {

            let count = 0;
            let max = selected.length

            this.pdfLoading = true;

            for (let item of selected) {
                // create filename
                item.filename = this.sanitizeFilenamePipe.transform(item.name);
                item.user = this.username;
                // create pdf
                this.pdfService.deletePdf(item).subscribe(
                    (data: any) => {
                        this.snotifyService.success(data.userMessage, { position: SnotifyPosition.rightTop });
                        count++;
                        // PDF job done
                        if (count === max) {
                            this.updatePdfMetaData();
                            this.pdfLoading = false;
                        }
                    },
                    (err: any) => {
                        // console.log(err)
                        this.pdfLoading = false;
                    }
                )
            }
        }
    }

    /** Saves zoom level */
    saveMapZoom(item: any, zoomLevel: string, stationId: string, stationName: string) {
        const zoom = {
            name: item._elementRef.nativeElement.id,
            level: zoomLevel,
            id: stationId,
            station: stationName
        }
        this.mapService.saveZoomLevel(zoom).subscribe(
            (data: any) => {
                // console.log(data)
                this.renderer.removeClass(item._elementRef.nativeElement, 'changes-not-saved');
                this.snotifyService.success(data.userMessage, { position: SnotifyPosition.rightTop });
            },
            (err: any) => {
                // console.log(err)
            }
        )
    }

    /** Builds static map url */
    getMapUrl(stationId: string, stationLat: string, stationLng: string, zoomLevel: string, spacesArray: any, mobiesArray: any, imageSize: string, linkOnly: boolean): string {
        return this.mapService.buildStaticUrl(stationId, stationLat, stationLng, zoomLevel, spacesArray, mobiesArray, imageSize, linkOnly)
    }

    /** Creates CSV file */
    makeCsv() {
        this.csvLoading = true;
        this.pdfService.createCsv(this.dataSource.data).subscribe(
            (data: any) => {
                // console.log(data)
                this.snotifyService.success(data.userMessage, { position: SnotifyPosition.rightTop });
                this.csvLoading = false;
            },
            (err: any) => {
                // console.log(err)
                this.csvLoading = false;
            }
        )
    }

    /** Creates ZIP file from eva PDFs */
    makeZip() {
        this.zipLoading = true;
        this.snotifyService.info('Das ZIP Archiv wird im Hintergrund erstellt.', { position: SnotifyPosition.rightTop, timeout: 10000 });
        this.pdfService.createZip().subscribe(
            (data: any) => {
                // console.log(data)
                this.snotifyService.success(data.userMessage, { position: SnotifyPosition.rightTop });
                this.zipLoading = false;
            },
            (err: any) => {
                // console.log(err)
                this.zipLoading = false;
            }
        )
    }

    openFile(fileName:string, fileType:string, targetBlank:boolean) : boolean {
        
        let url: string;
        let path: string;
        let extension: string;
        
        switch (fileType) {
            case 'pdfStation':
                path = environment.filePaths.pdfStation;
                extension = '.pdf';
                fileName = this.sanitizeFilenamePipe.transform(fileName);
                break;
            case 'pdfEvaNumber':
                path = environment.filePaths.pdfEvaNumber;
                extension = '.pdf';
                break;
            case 'previewImage':
                path = environment.filePaths.previewImage;
                extension = '.gif';
                break;
            default:
                return false;
        }

        url = this.phantomQuerystringPipe.transform(path + fileName + extension);

        window.open(url, targetBlank ? '_blank' : '_self');

        return true;
    }

    /** Opens zip archive bottom sheet */
    openBottomSheet(): void {
        this.bottomSheet.open(ArchivesBottomsheetComponent);
    }

    /** Opens preview PDF in dialog */
    openPdfPreviewDialog(element: any): void {
        this.dialog.open(PreviewDialogComponent, {
            data: element,
            panelClass: 'preview-dialog-container'
        });
    }

    onZoomChange(item: any) {
        this.renderer.addClass(item._elementRef.nativeElement, 'changes-not-saved');
    }

}
