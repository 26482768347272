import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private auth: AuthService,
        private router: Router
    ) { }

    canActivate() {
        // if user is logged in return true
        if (this.auth.isLoggedIn) return true
        // go to login if user not logged in
        return this.auth.validateAccessToken().pipe(
            map(res => {
                if (res.ok) {
                    return true
                }
                return false
            }),
            catchError((err: HttpErrorResponse) => {
                this.router.navigate([''])
                return of(false)
            })
        )
    }

}
