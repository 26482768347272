<mat-list>
    <div mat-subheader>ZIP Archive</div>
    <mat-list-item *ngFor="let file of zipArchives.files">
        <mat-icon mat-list-icon>archive</mat-icon>
        <div mat-line><a href="{{zipArchives.path}}{{file.name}}">{{file.name}}</a></div>
        <div mat-line>
            {{file.created | date: 'dd.MM.yyyy | HH:mm:ss' }} | {{file.count}} PDFs
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="zipArchives.files.length === 0">Keine Archive gefunden. Erstellen Sie zunächste ein Archiv.</mat-list-item>
</mat-list>