export interface PaginatorStateModel {
    pageIndex: number
    pageSize: number
    length: number
}

export class PaginatorChanged {
    static readonly type = '[Paginator] Changed';
    constructor(
        public paginationPDF: any
    ) {}  
}