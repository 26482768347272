import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';

import { DatePipe, registerLocaleData } from '@angular/common';
import localeDeAt from '@angular/common/locales/de-AT';

import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { HttpXsrfInterceptor } from '@core/interceptors/http-xsrf.interceptor';

import { getGermanPaginatorIntl } from './core/intl/german-paginator.intl';

import { AuthGuard } from './core/guards/auth.guard';

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';

import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
// import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-alt-snotify';

import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatSortModule } from '@angular/material/sort';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './core/layout/header/header.component';
import { UserDialogComponent } from './core/layout/header/user-dialog/user-dialog.component';
import { LogoutButtonComponent } from './core/layout/header/logout-button/logout-button.component';

import { SanitizeFilenamePipe } from './shared/pipes/sanitize-filename.pipe';
import { PreviewDialogComponent } from './home/preview-dialog/preview-dialog.component';
import { ArchivesBottomsheetComponent } from './home/archives-bottomsheet/archives-bottomsheet.component';
import { PhantomQuerystringPipe } from './shared/pipes/phantom-querystring.pipe';
import { PaginatorState } from '@core/states/paginator/paginator.state';
import { environment } from '@environments/environment';
import { SalutationPipe } from './shared/pipes/salutation.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    LoginComponent,
    UserDialogComponent,
    LogoutButtonComponent,
    SanitizeFilenamePipe,
    PreviewDialogComponent,
    ArchivesBottomsheetComponent,
    PhantomQuerystringPipe,
    SalutationPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ScrollingModule,
    // FlexLayoutModule,
    SnotifyModule,
    MatInputModule,
    MatGridListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatDialogModule,
    MatSliderModule,
    MatDividerModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatChipsModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatSelectModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    MatListModule,
    NgxsModule.forRoot([
      PaginatorState
    ], { developmentMode: !environment.production }),
    NgxsStoragePluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    // AngularStickyThingsModule,
    HttpClientXsrfModule.withOptions({
        cookieName: 'XSRF-TOKEN',
        headerName: 'X-XSRF-TOKEN',
    }),
  ],
  exports: [HttpClientModule],
  providers: [
    AuthGuard,
    SanitizeFilenamePipe,
    PhantomQuerystringPipe,
    SnotifyService,
    DatePipe,
    { provide: LOCALE_ID, useValue: 'de-at' },
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true, },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl() }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        registerLocaleData(localeDeAt);
    }
 }
