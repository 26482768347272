import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PaginatorChanged, PaginatorStateModel } from './paginator.action';

@State<PaginatorStateModel>({
	name: 'paginatorPdfGenerator',
	defaults: {
		pageIndex: 0,
		pageSize: 200,
		length: 0
    }
})
@Injectable()
export class PaginatorState {

	constructor() {}

	@Selector()
	static getCurrentPageSize(state: PaginatorStateModel) {
		return state.pageSize
	}

	@Selector()
	static getCurrentPageIndex(state: PaginatorStateModel) {
		return state.pageIndex
	}

	@Action(PaginatorChanged)
	PaginatorChanged(ctx: StateContext<PaginatorStateModel>, action: PaginatorChanged) {
		ctx.patchState(action.paginationPDF)
	}

}