import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PdfService {

    constructor(
        private http: HttpClient
    ) { }

    createPdf(selection: any): Observable<any> {
        return this.http.post(`${environment.crudUrl}/createPDF.php`, selection)
    }

    deletePdf(selection: any): Observable<any> {
        return this.http.post(`${environment.crudUrl}/deletePDF.php`, selection)
    }

    previewPdf(selection: any): Observable<any> {
        return this.http.post(`${environment.crudUrl}/previewPDF.php`, selection)
    }

    createCsv(stations: any): Observable<any> {
        return this.http.post(`${environment.crudUrl}/createCSV.php`, stations)
    }

    createZip(): Observable<any> {
        return this.http.get<any>(`${environment.crudUrl}/createZIP.php`)
    }

    getMetaData(): Observable<any> {
        return this.http.get<any>(`${environment.crudUrl}/getMetaData.php`)
    }

    countFiles(types: string[] = ['EVA', 'GIF', 'PDF', 'ZIP']): Observable<any> {
        return this.http.post(`${environment.crudUrl}/countFiles.php`, types)
    }

    getZipFiles(): Observable<any> {
        return this.http.get<any>(`${environment.crudUrl}/getZipFiles.php`)
    }

}
