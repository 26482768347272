import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(
        request: HttpRequest<unknown>, 
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {

        request = request.clone({
            withCredentials: true
        });
        
        return next.handle(request);

    }
}