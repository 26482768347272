import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MapService } from '../map.service';
import { PdfService } from '../pdf.service';
// import { DomSanitizationService } from '@angular/platform-browser';

@Component({
    selector: 'app-preview-dialog',
    templateUrl: './preview-dialog.component.html',
    styleUrls: ['./preview-dialog.component.scss']
})
export class PreviewDialogComponent implements OnInit {

    isLoading: boolean = true;
    pdfPreviewBase64: SafeResourceUrl;

    constructor(
        private pdfService: PdfService,
        private mapService: MapService,
        private domSanitizer: DomSanitizer,
        public dialogRef: MatDialogRef<PreviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit(): void {
        this.data.mapUrl = this.mapService.buildStaticUrl(
            this.data.id, 
            this.data.geoLocation.latitude, 
            this.data.geoLocation.longitude, 
            this.data.pdfmeta.zoom, 
            this.data.spaces.draftsIncluded, 
            this.data.mobies.draftsIncluded,
            'big', 
            true
        );
        this.data.thumbnailUrl = this.mapService.buildStaticUrl(
            this.data.id, 
            this.data.geoLocation.latitude, 
            this.data.geoLocation.longitude, 
            this.data.pdfmeta.zoom2, 
            this.data.spaces.draftsIncluded, 
            null,
            'thumbnail', 
            true
        );

        console.log(this.data)
        // this.data.filename = this.sanitizeFilenamePipe.transform(this.data.name);
        // this.data.user = username;
        // create pdf
        this.pdfService.previewPdf(this.data).subscribe(
            (data: any) => {
                this.pdfPreviewBase64 = this.domSanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + data.base64);
                this.isLoading = false;
            },
            (err: any) => {
                this.dialogRef.close();
            }
        )
 
    }

    getPdfPreviewBase64String(): any {

    }

}
