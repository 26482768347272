import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Role } from '@core/enums/role.enum';
import { User } from '@core/layout/header/user-dialog/user.interface';
import { AuthService } from '@core/services/auth.service';
import { DataService } from '@core/services/data.service';
import { SharedService } from '@core/services/shared.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class RoleGuard implements CanActivate {

	private allowedRoles: string[] = [
		Role.MANAGER,
		Role.ADMIN,
		Role.USER,
		Role.MOBI
	]

	constructor(
		private dataService: DataService,
		private authService: AuthService,
		private sharedService: SharedService,
		private snackBar: MatSnackBar,
		private router: Router
	) { }


	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		return this.dataService.getUser().pipe(
			map((data: User) => {
				this.sharedService.setCurrentUser(data);
				return this.allowedRoles.includes(data.role);
			}),
			tap((isAllowed: boolean) => {
				if (!isAllowed) {
					this.authService.revokeAccessToken().subscribe(() => {
						this.authService.setLoggedIn(false);
						this.router.navigate(['/']);
						this.snackBar.open(
							'Keine ausreichende Berechtigung. Bitte wenden Sie sich an den Support.',
							'',
							{ duration: 5000, panelClass: 'error-snack' }
						);
					});
				}
			})
		);
	}

}
