import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../layout/header/user-dialog/user.interface';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    private user = new BehaviorSubject<boolean|User>(false);

    currentUser = this.user.asObservable();

    constructor() { }

    setCurrentUser(user:any) {
        this.user.next(user)
    }

    removeCurrentUser() {
        this.user.next(false)
    }

}
