import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { environment } from '@environments/environment';

import { PdfService } from '../pdf.service';

@Component({
    selector: 'app-archives-bottomsheet',
    templateUrl: './archives-bottomsheet.component.html',
    styleUrls: ['./archives-bottomsheet.component.scss']
})
export class ArchivesBottomsheetComponent implements OnInit {

    baseUrl: string = environment.crudUrl;
    zipArchives: any = { files: [], path: null };
    isLoading: boolean = true;
    
    constructor(
        public dialogRef: MatBottomSheetRef<ArchivesBottomsheetComponent>,
        private pdfService: PdfService,
    ) { }

    ngOnInit(): void {
        /** Get all ZIP Archives */
        this.pdfService.getZipFiles().subscribe(
            (data: any) => {
                console.log(data)
                this.zipArchives = data;
                this.isLoading = false;
            },
            (err: any) => {
                // console.log(err)
            }
        )
    }

}
