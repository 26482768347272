import { Pipe, PipeTransform } from '@angular/core';

interface CharacterMap { [key: string]: string; };

@Pipe({
    name: 'sanitizeFilename'
})
export class SanitizeFilenamePipe implements PipeTransform {

    replaceSpecialCharacters(unnormalizedChar: string, normalizedChar: string, input: string): string {
        let regex = new RegExp(unnormalizedChar, 'g');
        return input.replace(regex, normalizedChar);
    }

    transform(input: string): string {

        const characterMap:CharacterMap = {
            'Ä': 'Ae',
            'Ö': 'Oe',
            'Ü': 'Ue',
            'ä': 'ae',
            'ö': 'oe',
            'ü': 'ue',
            'ß': 'ss',
            ' ': '_',
            '-': '_'
        }

        for ( let [key, value] of Object.entries(characterMap)) {
            input = this.replaceSpecialCharacters(key, value, input);
        }

        input = input.replace(/\./g, '');
        input = input.replace(/\(|\)/g, '');

        return input;
    }

}
